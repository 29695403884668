import React, { createContext, useState, useEffect } from "react"

const defaultState = {
  customer: {
    email: "",
  },
  shippingAddress: {
    first_name: "",
    last_name: "",
    company: "",
    address: "",
    address_opt: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
  },
  paymentMethod: {
    type: "",
  },
  billingAddress: {
    first_name: "",
    last_name: "",
    company: "",
    address: "",
    address_opt: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    useShippingAddress: false,
  },
  isValid: {
    customer: false,
    shippingAddress: false,
    paymentMethod: false,
    billingAddress: false,
  },
  isError: true,
  isRequiredMessage: false,
  isAddressShouldReset: false,
  setIsAddressShouldReset: () => {},
  setCustomer: () => {},
  setShippingAddress: () => {},
  setPaymentMethod: () => {},
  setBillingAddress: () => {},
  setIsValid: () => {},
  setIsRequiredMessage: () => {},
}

const CheckoutContext = createContext(defaultState)

export const CheckoutContextProvider = ({ children }) => {
  const [customer, setCustomer] = useState(defaultState.customer)
  const [shippingAddress, setShippingAddress] = useState(
    defaultState.shippingAddress
  )
  const [paymentMethod, setPaymentMethod] = useState(defaultState.paymentMethod)
  const [billingAddress, setBillingAddress] = useState(
    defaultState.billingAddress
  )
  const [isValid, setIsValid] = useState(defaultState.isValid)
  const [isError, setIsError] = useState(defaultState.isError)
  const [isRequiredMessage, setIsRequiredMessage] = useState(defaultState.isRequiredMessage)
  const [isAddressShouldReset, setIsAddressShouldReset] = useState(false)

  useEffect(() => {
    const isAnyErrors = Object.values(isValid).some(value => !value)

    setIsError(isAnyErrors)
  }, [isValid])

  return (
    <CheckoutContext.Provider
      value={{
        customer,
        shippingAddress,
        paymentMethod,
        billingAddress,
        isValid,
        isError,
        isRequiredMessage,
        isAddressShouldReset,
        setIsAddressShouldReset,
        setCustomer,
        setShippingAddress,
        setPaymentMethod,
        setBillingAddress,
        setIsValid,
        setIsRequiredMessage,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export default CheckoutContext
