import React, { useState, useEffect, useContext } from "react"
import debounce from "lodash/debounce"
import axios from "axios"

import "./style.scss"
import NewButton from "../NewButton"
import CartContext from "../../context/cartContext"
import CustomerContext from "../../context/customerContext"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";

const CheckoutDiscount = ({title}) => {
  const { stripeCustomer } = useContext(CustomerContext)
  const { setDiscountPercentage, setDiscountCode, cartItems } = useContext(CartContext)
  const [isStripeCustomer, setIsStripeCustomer] = useState(stripeCustomer?.type === "stripe")
  const [approvedDiscount, setApprovedDiscount] = useState("")
  const [discountAmount, setDiscountAmount] = useState(0)
  const discountCode = JSON.parse(localStorage.getItem("discount_code"))?.discountCode
  const discountsList = {
    frames: ["teachers2023", "backtoschool50", "editor", "cf25frames", "cf-25-frames", "gift40"],
    general: ["editor", "USI10"],
    contact_lens: ["SIMPLYCODES10","PERKOPOLIS10"],
    frames_accessories: ["laborday30"],
  }
  const isNewRxDesktop = useNewRxFlowDesktop()

  useEffect(() => {
    setIsStripeCustomer(stripeCustomer?.type === "stripe")
  }, [stripeCustomer])

  const checkDiscountExisting = debounce(code => {
    const stripeEndpoint = "/api/v1/stripe/check_discounts"
    const rechargeEndpoint = "/stripe/get_discount_code"

    // TODO: Recharge endpoint use code query_params. Stripe query_param is discount_code
    axios.get(`${process.env.GATSBY_API_URL_STRIPE}${true ? stripeEndpoint : rechargeEndpoint}`, {
      params: { discount_code: code },
    })
    .then(response => {
        const { code_exists, discount_percentage } = response.data
        localStorage.setItem("frames_discount", JSON.stringify(discountsList.frames.some(discount => code?.toLowerCase()?.includes(discount.toLowerCase()))))
        localStorage.setItem("general_discount", JSON.stringify(discountsList.general.some(discount => code?.toLowerCase()?.includes(discount.toLowerCase()))))
        localStorage.setItem("contact_lens_discount", JSON.stringify(discountsList.contact_lens.some(discount => code?.toLowerCase()?.includes(discount.toLowerCase()))))
        localStorage.setItem("frames_accessories_discount", JSON.stringify(discountsList.frames_accessories.some(discount => code?.toLowerCase()?.includes(discount.toLowerCase()))))

        if (code_exists) {
          setApprovedDiscount(code)
          setDiscountAmount(discount_percentage/100)
        }
      })
      .catch(error => console.log(error))
  }, 500)

  const handleChange = e => {
    const {
      target: { value },
    } = e

    setApprovedDiscount("")

    value.trim() && checkDiscountExisting(value.trim())
  }

  useEffect(() => {
    checkDiscountExisting(discountCode)
  }, [])

  return (
    <div className="checkout-discount">
      <p className="title text h8 bulky deep-blue">{title}</p>
      <input
        className={`discount-input text h8 neutral ${isNewRxDesktop ? 'rx-desktop' : ''}`}
        defaultValue={discountCode}
        onChange={handleChange}
        placeholder={isNewRxDesktop ? "Enter Code" : "Enter discount"}
      />
      <NewButton
        type="button"
        label="Apply Discount"
        disabled={!approvedDiscount}
        onClick={() => { setDiscountPercentage(discountAmount); setDiscountCode(approvedDiscount) }}
      />
    </div>
  )
}

export default CheckoutDiscount
