import React, { useContext } from "react"

import "./style.scss"
import CartContext from "../../context/cartContext"
import CheckoutItemsList from "../CheckoutItemsList"
import CheckoutDiscount from "../CheckoutDiscount"
import CheckoutTotal from "../CheckoutTotal"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";

const CheckoutOrderInfo = () => {
  const { cartItems } = useContext(CartContext)
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  return (
    <div className={`checkout-order-info ${isNewRx ? "new-rx-flow" : ""} ${isNewRxDesktop ? "new-rx-desktop" : ""}`}>
      <div className="sticky-info">
        <h5 className="heading text h5 deep-blue">Order Summary</h5>
        <CheckoutItemsList items={cartItems} />
        {!isNewRx && !isNewRxDesktop && <CheckoutDiscount title='Discount' />}
        <CheckoutTotal items={cartItems} />
      </div>
    </div>
  )
}

export default CheckoutOrderInfo
