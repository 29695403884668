import React from 'react';
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

const LoadingWrapper = ({ condition, width, highlightColor, height, baseColor, count, children}) => {
  const loadingSkeletonFragment = <Skeleton highlightColor={highlightColor} baseColor={baseColor} width={width} height={height} count={count}/>
  return condition ? loadingSkeletonFragment : children
}

export default LoadingWrapper;
