import React from "react"

import "./style.scss"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import useNewRxFlow from "../../utils/useNewRxFlow";

const CheckoutInput = ({ type, name, label, value, onChange, placeholder, defaultValue }) => {
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  return (
    <div className={`checkout-input ${isNewRxDesktop || isNewRx ? "new-rx" : ""}`}>
      {label && <p className="title text h8 bulky">{label}</p>}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className="text h8 neutral"
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default CheckoutInput
