import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"

import "../styles/pages/checkout.scss"
import CartContext from "../context/cartContext"
import CheckoutCustomerInfo from "../components/CheckoutCustomerInfo"
import CheckoutOrderInfo from "../components/CheckoutOrderInfo"
import CheckoutDiscount from "../components/CheckoutDiscount"
import RecommendsList from "../components/RecommendsList"
import SEO from "../components/seo"
import { CheckoutContextProvider } from "../context/checkoutContext"
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {recommendsList, skyhyMonthlyRecommendsList} from "../components/YourCart/mockedData"
import useNewRxFlow from "../utils/useNewRxFlow"
import useNewRxFlowDesktop from "../utils/useNewRxFlowDesktop";
import PreventBottomScroll from "../components/PreventBottomScroll";

const Checkout = ({ location }) => {
  const { cartItems, isCartInitialized } = useContext(CartContext)
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);
  const hasSkyhyMonthly = cartItems.some(e => e.value === "skyhy-monthly")
  const fullRecommendsList = hasSkyhyMonthly ? skyhyMonthlyRecommendsList : recommendsList
  const [recommendedItemsList, setRecommendedItemsList] = useState(fullRecommendsList)
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const previousPageFromSessionStorage = typeof sessionStorage !== 'undefined' && (sessionStorage.getItem("previousPage"))
  const previousPage = ((isNewRx || isNewRxDesktop) && previousPageFromSessionStorage) ? `${previousPageFromSessionStorage}?newRxFlow=true` : "/"

  useEffect(() => {
    const newRecommendsList = fullRecommendsList.filter((item) => !cartItems.some((el) => el.product_id === item.product_id))
    setRecommendedItemsList(newRecommendsList)
  }, [cartItems])

  useEffect(() => {
    if (isCartInitialized && !cartItems.length) navigate("/")
  }, [cartItems])

  if (!cartItems.length) return null

  return (
    <CheckoutContextProvider>
      <SEO title="Checkout" />
      <div className="checkout-page">
        <div className="grid-section">
          <Elements stripe={stripePromise}>
            <CheckoutCustomerInfo backTo={(isNewRx || isNewRxDesktop) ? previousPage : location?.state?.link || "/"} />
          </Elements>
          {!isNewRx && !isNewRxDesktop && <CheckoutOrderInfo />}
          {isNewRx && (
            <div className="recommends-container">
              {recommendedItemsList.length !== 0 && (<>
                <h6 className="title text h6 deep-blue">
                  Recommended for You
                </h6>
                <RecommendsList recommendsList={recommendedItemsList} />
              </>)}
              <CheckoutDiscount title={"Do You Have a Promo Code?"} />
            </div>
          )}
          {isNewRxDesktop &&
            <div className="customer-order-info-container">
              <CheckoutOrderInfo />
              <div className="recommends-container">
                {recommendedItemsList.length !== 0 && (<>
                  <h6 className="title text h6 deep-blue">
                    Recommended for You
                  </h6>
                  <RecommendsList recommendsList={recommendedItemsList} />
                </>)}
                <CheckoutDiscount title={"Do You Have a Promo Code?"} />
              </div>
            </div>
          }
        </div>
      </div>
      <PreventBottomScroll height={'600px'} triggerTop={'150px'} />
    </CheckoutContextProvider>
  )
}

export default Checkout
