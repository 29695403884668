import React, { useState, useEffect, useContext, Fragment, createContext } from "react"
import { Link } from "gatsby"
import { useCookies } from "react-cookie"

import "./style.scss"
import CheckoutInput from "../CheckoutInput"
import PrescriptionRadio from "../PrescriptionRadio"
import CheckoutBlock from "../CheckoutBlock"
import OffTheSiteIcon from "../../images/assets/Links/Off-the-site-straight.svg"
import UserImage from "../UserImage"
import CheckoutContext from "../../context/checkoutContext"
import RequiredMessage from "../RequiredMessage"
import axios from "axios";
import LoadingWrapper from "../CustomerPortal/components-shared/LoadingWrapper"
import CustomerContext from "../../context/customerContext"
import CartContext from "../../context/cartContext"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";

const CheckoutContact = ({prescriptionEmail, emojiErrors, setEmojiErrors}) => {
  const [active, setActive] = useState("newsletter")
  const [cookies] = useCookies(["user-jwt"])
  const userToken = cookies["user-jwt"]

  const handleChangeActive = value => setActive(active === value ? "" : value)

  return (
    <CheckoutBlock id="contact">
      {userToken ? (
        <LoggedInBlock newsletter={active} onChange={handleChangeActive} userToken={userToken}/>
      ) : (
        <LoggedOutBlock newsletter={active} onChange={handleChangeActive} prescriptionEmail={prescriptionEmail} emojiErrors={emojiErrors} setEmojiErrors={setEmojiErrors} />
      )}
    </CheckoutBlock>
  )
}

const LoggedInBlock = ({ newsletter, onChange, userToken}) => {
  const { first_name, last_name, email } = JSON.parse(localStorage.getItem("customer_data")) || {}
  // const [customerData, setCustomerData] = useState(JSON.parse(localStorage.getItem("customer_data")))
  const {stripeCustomer, setStripeCustomer} = useContext(CustomerContext);
  const { customer, isValid, setCustomer, setIsValid } = useContext(
    CheckoutContext
  )
  const [cookie, setCookie, removeCookie] = useCookies(["user-jwt"])
  const [loadingCustomerData, setLoadingCustomerData] = useState(false)

  const logout = () => {
    removeCookie("user-jwt", { path: "/" })
    removeCookie("hubbleContactsSessionId")
    setIsValid({
      ...isValid,
      customer: false,
      paymentMethod: false,
      shippingAddress: false,
    })
    setCustomer({ ...customer, email: "" })
    setStripeCustomer({})
    localStorage.removeItem("customer_data")
    localStorage.removeItem("upcoming_order")
    localStorage.removeItem("customer_rx_sunglasses_data")
    localStorage.removeItem("customer_rx_glassess_data")
    localStorage.removeItem("discount_code")
  }

  useEffect(() => {
    if (userToken && stripeCustomer?.type !== "stripe") {
      setLoadingCustomerData(true)
      axios.get(`${process.env.GATSBY_API_URL}/api/customer_data`, {
        params: {
          user_token: userToken,
        },
      }).then((response) => {
        setStripeCustomer({...response.data.data, type: "recharge"})
        setCustomer({...response.data.data, type: "recharge"})
        localStorage.setItem('customer_data', JSON.stringify({...response.data.data, type: "recharge"}))
        setLoadingCustomerData(false)
      })
      .catch((error) => {})
    }
    setLoadingCustomerData(false)
    setCustomer({ ...customer, email })
    setIsValid({ ...isValid, customer: true })
  }, [email])

  return (
    <>
      <CheckoutBlock.Header title="Contact Information" />
      <CheckoutBlock.Body>
        <div className="logged-in-user">
          <div className="user-block">
            <UserImage />
            <div className="user-info">
              <LoadingWrapper condition={loadingCustomerData} width={250}>
                <Fragment>
                  <span className="name text h7 neue">
                    {Object.keys(stripeCustomer).length ? stripeCustomer?.first_name : first_name} {Object.keys(stripeCustomer).length  ? stripeCustomer?.last_name : last_name}
                  </span>
                </Fragment>
              </LoadingWrapper>
              <span className="email text h7 neue granite">{Object.keys(stripeCustomer).length ? stripeCustomer?.email : email}</span>
            </div>
          </div>
          <button className="logout-button" onClick={logout}>
            <span className="text h8 neutral logout">Log out</span>
            <OffTheSiteIcon />
          </button>
        </div>
        <PrescriptionRadio
          value="newsletter"
          label="Keep me up to date on news and exclusive offers"
          onClick={onChange}
          active={newsletter}
        />
      </CheckoutBlock.Body>
    </>
  )
}

const LoggedOutBlock = ({ newsletter, onChange, prescriptionEmail, emojiErrors, setEmojiErrors }) => {
  const { customer, isValid, setCustomer, setIsValid, isRequiredMessage } = useContext(
    CheckoutContext
  )
  const [smsSubscribe, setSmsSubscribe] = useState("")
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()

  useEffect(() => {
    if (prescriptionEmail) setIsValid({ ...isValid, customer: prescriptionEmail })
    return setCustomer({ ...customer, email: prescriptionEmail })
  }, [prescriptionEmail])

  const handleChangeEmail = ({ target: { value } }) => {
    setEmojiErrors({...emojiErrors, email: false})
    const isEmail = isValidEmail(value)

    if (isEmail) {
      if (!isValid.customer) {
        setIsValid({ ...isValid, customer: isEmail })
      }

      return setCustomer({ ...customer, email: value })
    }

    if (!isEmail && isValid.customer) {
      setIsValid({ ...isValid, customer: isEmail })
    }
  }

  const handleSignUpNewsletter = () => {
    setSmsSubscribe(smsSubscribe === "sms" ? "" :"sms")
    setCustomer({...customer, sms_subscribe: (smsSubscribe === "" ? true : false) })
  }

  const isValidEmail = email => /\S+@\S+\.\S+/.test(email)

  return (
    <>
      <CheckoutBlock.Header>
        <span className="title text h5">Contact Information</span>
        <div className="auth-block">
          <span className="text h8 granite">Have an account?</span>
          <Link className="text h8" to="/login" state={{ link: "/checkout" }}>
            Log in
          </Link>
        </div>
      </CheckoutBlock.Header>
      <CheckoutBlock.Body>
        <div>
          <CheckoutInput
            onChange={handleChangeEmail}
            type="email"
            label="Email Address"
            placeholder="Enter email"
            id="emailAddress"
            defaultValue={prescriptionEmail}
          />
          {isValidEmail && !isValid.customer && isRequiredMessage && <RequiredMessage />}
          {emojiErrors.email && <div className="error-block">Emojis are not allowed</div>}
        </div>
        <PrescriptionRadio
          value="newsletter"
          label="Keep me up to date on news and exclusive offers "
          onClick={onChange}
          active={newsletter}
        />
        {!isNewRx && !isNewRxDesktop &&
          <PrescriptionRadio
            value="sms"
            label="Check this box to receive special VIP rewards and promotions via text from Hubble at the phone number below. I understand that my consent is not a condition of purchase."
            onClick={() => handleSignUpNewsletter()}
            active={smsSubscribe}
        />}

      </CheckoutBlock.Body>
    </>
  )
}

export default CheckoutContact
