import React from "react"

import "./style.scss"

const CheckoutBlock = ({ id, children }) => (
  <div className="checkout-block" id={id}>
    {children}
  </div>
)

const Header = ({ title, children }) => (
  <div className="checkout-header">
    {children || <span className="title text h5">{title}</span>}
  </div>
)

const Body = ({ children }) => <div className="checkout-body">{children}</div>

CheckoutBlock.Header = Header
CheckoutBlock.Body = Body

export default CheckoutBlock
