import React from "react"

import "./style.scss"
import UserIcon from "../../images/assets/Header/User.svg"

const UserImage = ({ image }) => {
  return (
    <div className="user-image">
      {image ? (
        <div
          className="image-container"
          style={{ backgroundImage: `url(${image})` }}
        />
      ) : (
        <DefaultImage />
      )}
    </div>
  )
}

const DefaultImage = () => (
  <div className="default-image">
    <UserIcon />
  </div>
)

export default UserImage
