export const formLabels = {
  first_name: "First Name",
  last_name: "Last Name",
  company: "Company",
  address: "Address",
  city: "City",
  state: "State",
  zip: "Zip Code",
  phone: "Phone Number",
}

export const placeholderLabels = {
  first_name: "Enter first name",
  last_name: "Enter last name",
  company: "Enter company name (optional)",
  address: "Enter address",
  address_opt: "Apartment, suite, etc (optional)",
  city: "Enter city",
  state: "Select state",
  zip: "Enter ZIP",
  phone: "Enter phone number (optional)",
}
