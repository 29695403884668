
export const buildFrames = () => {
  const framesWithVariants =
    { "Andromeda": {
        "size": "Narrow",
        "sizing": "51-16-140",
        "shape": "Cat Eye",
        "men": [
          {
            "title": "Banana Tortoise"
          },
          {
            "title": "Black Tortoise"
          },
          {
            "title": "Crystal Blush"
          }
        ],
        "women": [
          {
            "title": "Banana Tortoise"
          },
          {
            "title": "Black Tortoise"
          },
          {
            "title": "Crystal Blush"
          }
        ],
        "related_frames_men_glasses": ["Ophelia", "Kepler", "Cassini"] ,
        "related_frames_women_glasses": ["Ophelia", "Kepler", "Cassini"],
        "related_frames_men_sunglasses": ["Ophelia", "Kepler", "Cassini"] ,
        "related_frames_women_sunglasses": ["Ophelia", "Kepler", "Cassini"],
      },

      "Ariel": {
        "size": "Wide",
        "sizing": "55-20-145",
        "shape": "Square/Rectangle",
        "men": [
          {
            "title": "Soft Slate"
          },
          {
            "title": "Burnt Caramel Tortoise"
          },
          {
            "title": "Black Tortoise"
          }
        ],
        "women": [
          {
            "title": "Burnt Caramel Tortoise"

          },
          {
            "title": "Black Tortoise"
          },
          {
            "title": "Soft Slate"
          }
        ],
        "sunglasses": {
          "Black Tortoise": "Gray-Green", "Burnt Caramel Tortoise": "Gray","Soft Slate" : "Gray-Green", "Eclipse" : "Gray"
        },
        "related_frames_men_glasses": ["Artemis", "Luna", "Neptune"] ,
        "related_frames_women_glasses": ["Artemis", "Luna", "Neptune"],
        "related_frames_men_sunglasses": ["Artemis", "Luna", "Neptune"] ,
        "related_frames_women_sunglasses": ["Artemis", "Luna", "Neptune"],

      },
      "Artemis": {
        "size": "Wide",
        "sizing": "53-20-145",
        "shape": "Square/Round",
          "men": [
              {
                "title": "Soft Slate"
              },
              {
                "title": "Butterscotch"
              },
              {
                "title": "Black Tortoise"
              }
            ],
            "women": [
              {
                "title": "Soft Slate"
              },
              {
                "title": "Butterscotch"
              },
              {
                "title": "Black Tortoise"
              }
            ],
            "sunglasses": {
              "Black Tortoise": "Gray", "Butterscotch": "Gray-Green","Soft Slate" : "Gray-Green", "Eclipse" : "Gray"
            },
            "related_frames_men_glasses": ["Ariel", "Luna", "Neptune"] ,
            "related_frames_women_glasses": ["Ariel", "Luna", "Neptune"],
            "related_frames_men_sunglasses": ["Ariel", "Luna", "Neptune"] ,
            "related_frames_women_sunglasses": ["Ariel", "Luna", "Neptune"],
        },
        "Astrid": {
          "size": "Medium",
          "sizing": "50-20-145",
          "shape": "Square/Round",
          "men": [
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Champagne Crystal"
            }
            ],
          "women": [
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Champagne Crystal"
            }
          ],
          "sunglasses": {
            "Black Tortoise": "Gray-Green", "Blush Tortoise": "Brown","Champagne Crystal" : "Brown"
          },
            "related_frames_men_glasses": ["Ariel", "Luna", "Neptune"] ,
            "related_frames_women_glasses": ["Ariel", "Luna", "Titania"],
            "related_frames_men_sunglasses": ["Neptune", "Nebula", "Kepler"] ,
            "related_frames_women_sunglasses": ["Neptune", "Nebula", "Titania"],
        }            ,
        "Callisto": {
          "size": "Medium",
          "sizing": "50-19-145",
          "shape": "Square",
          "men": [
            {
              "title": "Matte Eclipse"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Soft Slate"
            }
            ],
          "women": [
            {
              "title": "Matte Eclipse"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Soft Slate"
            }
            ],
            "sunglasses": {
              "Black Tortoise": "Gray-Green", "Matte Eclipse": "Gray-Green", "Soft Slate" : "Gray-Green"
            },
            "related_frames_men_glasses": ["Lyra", "Nebula", "Titan"],
            "related_frames_women_glasses": ["Lyra", "Nebula", "Titan"],
            "related_frames_men_sunglasses": ["Lyra", "Nebula", "Titan"],
            "related_frames_women_sunglasses": ["Lyra", "Nebula", "Titan"],
        },

        "Cassini": {
          "size": "Narrow",
          "sizing": "48-18-145",
          "shape": "Square",
          "men": [
            {
              "title": "Sun Spot Tortoise"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Crystal Blush"
            }
            ],
          "women": [
            {
              "title": "Crystal Blush"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Sun Spot Tortoise"
            }
          ],
          "sunglasses": {
            "Black Tortoise": "Gray-Green", "Sun Spot Tortoise": "Gray-Green", "Crystal Blush" : "Brown"
          },
          "related_frames_men_glasses": ["Kepler", "Galileo", "Callisto"],
          "related_frames_women_glasses": ["Kepler", "Galileo", "Callisto"],
          "related_frames_men_sunglasses": ["Kepler", "Galileo", "Callisto"],
          "related_frames_women_sunglasses": ["Kepler", "Galileo", "Callisto"],
        },

        "Doppler": {
          "size": "Narrow/Medium",
          "sizing": "50-18-145",
          "shape": "Rectangle",
          "men": [
            {
              "title": "Eclipse"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Midnight Blue"
            }
            ],
          "women": [
            {
              "title": "Eclipse"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Midnight Blue"
            }
          ],
          "related_frames_men_glasses": ["Orion", "Kepler", "Callisto"],
          "related_frames_women_glasses": ["Kepler", "Juno", "Orion"],
          "related_frames_men_sunglasses": ["Orion", "Kepler", "Callisto"],
          "related_frames_women_sunglasses": ["Kepler", "Juno", "Orion"],
        },

        "Galileo": {
          "size": "Narrow",
          "sizing": "46-20-145",
          "shape": "Round",
          "men": [
            {
              "title": "Sun Spot Tortoise"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Cerulean Crystal"
            }
            ],
          "women": [
            {
              "title": "Cerulean Crystal"
            },
            {
              "title": "Sun Spot Tortoise"
            },
            {
              "title": "Black Tortoise"
            }
          ],
          "sunglasses": {
            "Cerulean Crystal": "Gray", "Sun Spot Tortoise": "Gray-Green", "Black Tortoise" : "Gray-Green"
          },
          "related_frames_men_glasses": ["Kepler", "Ariel", "Cassini"] ,
          "related_frames_women_glasses": ["Kepler", "Juno", "Cassini"],
          "related_frames_men_sunglasses": ["Kepler", "Ariel", "Cassini"],
          "related_frames_women_sunglasses": ["Kepler", "Juno", "Cassini"],
        },

        "Juno": {
          "size": "Medium",
          "sizing": "50-20-145",
          "shape": "Cat Eye",
          "men": [
            {
              "title": "Aurora Tortoise"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Soft Slate"
            }
          ],
          "women": [
            {
              "title": "Aurora Tortoise"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Soft Slate"
            }
          ],
          "sunglasses": {
            "Aurora Tortoise": "Gray", "Black Tortoise": "Gray-Green", "Soft Slate" : "Gray-Green"
          },
          "related_frames_men_glasses": ["Astrid", "Titania", "Callisto"] ,
          "related_frames_women_glasses": ["Astrid", "Titania", "Callisto"],
          "related_frames_men_sunglasses": ["Astrid", "Titania", "Callisto"] ,
          "related_frames_women_sunglasses": ["Astrid", "Titania", "Callisto"],
        },

        "Kepler": {
          "size": "Narrow/Medium",
          "sizing": "47-20-145",
          "shape": "Round",
          "men": [
            {
              "title": "Emerald Crystal"
            },
            {
              "title": "Matte Eclipse"
            },
            {
              "title": "Sun Spot Tortoise"
            }
            ],
          "women": [
            {
              "title": "Sun Spot Tortoise"
            },
            {
              "title": "Matte Eclipse"
            },
            {
              "title": "Emerald Crystal"
            }
          ],
          "sunglasses": {
            "Sun Spot Tortoise": "Gray", "Emerald Crystal": "Brown", "Matte Eclipse" : "Gray-Green","Soft Slate" : "Gray"
          },
          "related_frames_men_glasses": ["Galileo","Polaris","Nova"] ,
          "related_frames_women_glasses": ["Galileo","Polaris","Nova"] ,
          "related_frames_men_sunglasses":  ["Galileo","Polaris","Astrid"] ,
          "related_frames_women_sunglasses": ["Galileo","Polaris","Titania"],
        },

        "Luna": {
            "size": "Wide",
            "sizing": "52-18-140",
            "shape": "Round",
          "men": [
            {
              "title": "Butterscotch"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Champagne Crystal"
            }
          ],
          "women": [
            {
              "title": "Champagne Crystal"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Butterscotch"
            }
          ],
          "sunglasses": {
            "Black Tortoise": "Gray-Green", "Butterscotch": "Gray", "Champagne Crystal" : "Brown"
          },
          "related_frames_men_glasses": ["Ariel","Artemis","Neptune"] ,
          "related_frames_women_glasses": ["Ariel","Artemis","Neptune"]  ,
          "related_frames_men_sunglasses": ["Ariel","Artemis","Neptune"] ,
          "related_frames_women_sunglasses": ["Ariel","Artemis","Neptune"],
        },

        "Lyra": {
          "size": "Medium",
          "sizing": "52-16-145",
          "shape": "Square",
          "men": [
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Mars Crystal"
            }
            ],
          "women": [
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Mars Crystal"
            },
            {
              "title": "Black Tortoise"
            }
          ],
          "sunglasses": {
            "Black Tortoise": "Gray-Green", "Mars Crystal": "Gray","Blush Tortoise" : "Gray","Eclipse": "Gray"
          },
          "related_frames_men_glasses": ["Callisto","Nebula","Titan"] ,
          "related_frames_women_glasses": ["Callisto","Nebula","Titan"]  ,
          "related_frames_men_sunglasses": ["Callisto","Nebula","Titan"] ,
          "related_frames_women_sunglasses": ["Callisto","Nebula","Titan"],
        },

        "Nebula": {
          "size": "Medium",
          "sizing": "50-17-140",
          "shape": "Square",
          "men": [
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Butterscotch"
            },
            {
              "title": "Soft Slate"
            }
            ],
          "women": [
            {
              "title": "Soft Slate"
            },
            {
              "title": "Butterscotch"
            },
            {
              "title": "Black Tortoise"
            }
          ],
          "sunglasses": {
            "Black Tortoise": "Gray", "Butterscotch": "Gray-Green","Soft Slate" : "Gray-Green"
          },
          "related_frames_men_glasses": ["Lyra","Callisto","Titan"] ,
          "related_frames_women_glasses": ["Lyra","Callisto","Titan"]  ,
          "related_frames_men_sunglasses": ["Lyra","Callisto","Titan"] ,
          "related_frames_women_sunglasses": ["Lyra","Callisto","Titan"],
        },

        "Neptune": {
          "size": "Medium/Wide",
          "sizing": "49-20-145",
          "shape": "Square",
          "men": [
            {
              "title": "Midnight Blue"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Blush Tortoise"
            }
            ],
          "women": [
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Midnight Blue"
            },
            {
              "title": "Black Tortoise"
            }
          ],
          "sunglasses": {
            "Black Tortoise": "Gray", "Midnight Blue": "Gray","Blush Tortoise" : "Brown","Soft Slate" : "Gray"
          },
          "related_frames_men_glasses": ["Nebula","Astrid","Polaris"] ,
          "related_frames_women_glasses": ["Nebula","Astrid","Polaris"]  ,
          "related_frames_men_sunglasses": ["Nebula","Astrid","Polaris"] ,
          "related_frames_women_sunglasses": ["Nebula","Astrid","Polaris"],
        },

        "Nova": {
          "size": "Narrow",
          "sizing": "47-19-145",
          "shape": "Square/Round",
          "men": [
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Champagne Crystal"
            },
            {
              "title": "Sun Spot Tortoise"
            }
            ],
          "women": [
            {
              "title": "Sun Spot Tortoise"
            },
            {
              "title": "Champagne Crystal"
            },
            {
              "title": "Black Tortoise"
            }
          ],
          "related_frames_men_glasses": ["Galileo","Kepler","Cassini"] ,
          "related_frames_women_glasses": ["Galileo","Kepler","Cassini"]   ,
          "related_frames_men_sunglasses": ["Galileo","Kepler","Cassini"]  ,
          "related_frames_women_sunglasses": ["Galileo","Kepler","Cassini"] ,
        },

        "Ophelia": {
          "size": "Narrow",
          "sizing": "48-16-140",
          "shape": "Cat Eye",
          "men": [
            {
              "title": "Crystal Blush"
            },
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Black Tortoise"
            }
          ],
          "women": [
            {
              "title": "Crystal Blush"
            },
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Black Tortoise"
            }
          ],
          "related_frames_men_glasses": ["Juno","Galileo","Orion"] ,
          "related_frames_women_glasses": ["Juno","Galileo","Orion"]    ,
          "related_frames_men_sunglasses": ["Juno","Galileo","Orion"]   ,
          "related_frames_women_sunglasses": ["Juno","Galileo","Orion"]  ,
        },

        "Orion": {
          "size": "Narrow/Medium",
          "sizing": "49-19-145",
          "shape": "Rectangle",
          "men": [
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Eclipse"
            },
            {
              "title": "Midnight Blue"
            }
            ],
          "women": [
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Eclipse"
            },
            {
              "title": "Midnight Blue"
            }
          ],
          "related_frames_men_glasses": ["Doppler","Kepler","Callisto"] ,
          "related_frames_women_glasses": ["Doppler","Kepler","Callisto"]   ,
          "related_frames_men_sunglasses": ["Doppler","Kepler","Callisto"]  ,
          "related_frames_women_sunglasses": ["Doppler","Kepler","Callisto"] ,
        },

        "Polaris": {
          "size": "Medium",
          "sizing": "49-21-145",
          "shape": "Round",
          "men": [
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Galactic Tortoise"
            },
            {
              "title": "Champagne Crystal"
            }
            ],
          "women": [
            {
              "title": "Champagne Crystal"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Galactic Tortoise"
            }
          ],
          "sunglasses": {
            "Black Tortoise": "Gray-Green", "Champagne Crystal": "Gray-Green","Galactic Tortoise" : "Gray"
          },
          "related_frames_men_glasses": ["Astrid","Kepler","Neptune"] ,
          "related_frames_women_glasses": ["Titania","Kepler","Callisto"]   ,
          "related_frames_men_sunglasses": ["Astrid","Kepler","Neptune"] ,
          "related_frames_women_sunglasses": ["Titania","Kepler","Callisto"] ,
        },

        "Titan": {
          "size": "Medium",
          "sizing": "50-19-145",
          "shape": "Square",
          "men": [
            {
              "title": "Sun Spot Tortoise"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Champagne Crystal"
            }
          ],
          "women": [
            {
              "title": "Sun Spot Tortoise"
            },
            {
              "title": "Black Tortoise"
            },
            {
              "title": "Champagne Crystal"
            }
          ],
          "sunglasses": {
            "Black Tortoise": "Gray-Green", "Champagne Crystal": "Brown","Sun Spot Tortoise" : "Gray"
          },
          "related_frames_men_glasses": ["Callisto","Lyra","Nebula"] ,
          "related_frames_women_glasses": ["Callisto","Lyra","Nebula"]   ,
          "related_frames_men_sunglasses": ["Callisto","Lyra","Nebula"],
          "related_frames_women_sunglasses": ["Callisto","Lyra","Nebula"] ,
        },

        "Titania": {
          "size": "Medium",
          "sizing": "51-20-145",
          "shape": "Round",
          "men": [
            {
              "title": "Midnight Blue"
            },
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Eclipse"
            }
          ],
          "women": [
            {
              "title": "Midnight Blue"
            },
            {
              "title": "Blush Tortoise"
            },
            {
              "title": "Eclipse"
            }
          ],
          "sunglasses": {
              "Eclipse": "Gray-Green", "Blush Tortoise": "Gray","Midnight Blue" : "Gray"
          },
          "related_frames_men_glasses": ["Astrid","Polaris","Juno"] ,
          "related_frames_women_glasses": ["Astrid","Polaris","Juno"],
          "related_frames_men_sunglasses": ["Astrid","Polaris","Juno"] ,
          "related_frames_women_sunglasses": ["Astrid","Polaris","Juno"]  ,
        }
    }
    return framesWithVariants
  }














